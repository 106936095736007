<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <b-row class="mt-4">
      <b-col>
        <i class="fa fa-backward"></i>
        <router-link :to="{ name: SidebarMenuListPath.name }"> Menus </router-link>
      </b-col>
      <b-col class="text-right">
        <b-badge variant="dark" class="btn btn-link" @click="onCopyMenuFrom">copy from super admin</b-badge>
      </b-col>
    </b-row>
    <b-card :header="roles[role_id]" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
      <b-row v-for="(item, idx) in menuList" :key="item.id">
        <b-col md="10">
          <span id="menu-name" style="min-width:300px">
            <a v-b-toggle="'children' + idx" class="btn">
              <i :class="item.icon"></i> {{ item.name }}
              <b v-if="item.title">[Title]</b>
            </a>
            <span v-if="item.is_badge">
              <b-badge :variant="item.badge.variant">
                {{ item.badge.text }}
              </b-badge>
            </span>
          </span>
          <span v-if="!item.title">
            <span class="px-3">|</span>
            <span id="menu-btn-edit" class="mr-2">
              <b-badge v-b-toggle="'edit' + idx" class="btn btn-link " variant="primary">edit</b-badge>
            </span>
            <span id="menu-btn-child" v-if="item.children?.length" class="mr-2">
              <b-badge v-b-toggle="'children' + idx" class="btn btn-link " variant="info">show child</b-badge>
            </span>
            <span id="menu-btn-add-child" v-if="!item.title && !item.children?.length" class="mr-2">
              <b-badge v-b-modal.modal-add-child-menu class="btn btn-link " variant="warning"
                @click="setParentMenu(idx, -1)">Add child</b-badge>
            </span>
            <span id="menu-btn-child" class="mr-2">
              <b-badge class="btn btn-link " variant="danger" @click="onDeleteMenu(idx)">remove</b-badge>
            </span>
          </span>

          <b-collapse :id="'edit' + idx">
            <ul>
              <li>Name <input type="text" v-model="item.name" />
              </li>
              <!-- <li>Is Title <input type="checkbox" v-model="item.title" @click="onTitleAction($event, item)" /></li> -->
              <section v-if="!item.title">
                <li>URL <input type="text" v-model="item.url" /></li>
                <li>Icon <input type="text" v-model="item.icon" /></li>
                <li>badge :
                  <input type="checkbox" @click="onBadgeAction($event, item)" v-model="item.is_badge">
                  <span v-if="item.is_badge">
                    <input type="text" v-model="item.badge.variant" placeholder="variant">
                    <input type="text" v-model="item.badge.text" placeholder="text">
                  </span>
                </li>
              </section>
            </ul>
          </b-collapse>

          <b-collapse :id="'children' + idx" v-if="item.children">
            <b-row>
              <b-col md="10">
                <ul v-for="(child, idxChild) in item.children" :key="idxChild">
                  <span id="child-name" style="min-width: 300px;">
                    <a v-b-toggle="'editchild' + idx + idxChild" class="btn">
                      <i :class="child.icon"></i> {{ child.name }}
                    </a>
                    <span v-if="child.is_badge">
                      <b-badge :variant="child.badge.variant">{{ child.badge.text }}</b-badge>
                    </span>
                  </span>
                  <span class="px-3">|</span>
                  <span>
                    <b-badge v-b-toggle="'editchild' + idx + idxChild" class="btn btn-link mr-2"
                      variant="secondary">edit</b-badge>
                  </span>
                  <span>
                    <b-badge class="btn btn-link mr-2" variant="danger"
                      @click="onDeleteChildMenu(item, idxChild)">remove</b-badge>
                  </span>
                  <b-collapse :id="'editchild' + idx + idxChild">
                    <ul>
                      <li>name : <input type="text" v-model="child.name"></li>
                      <li>url : <input type="text" v-model="child.url"></li>
                      <li>icon : <input type="text" v-model="child.icon"></li>
                      <li>badge :
                        <input type="checkbox" @click="onBadgeAction($event, child)" v-model="child.is_badge">
                        <span v-if="child.is_badge">
                          <input type="text" v-model="child.badge.variant" placeholder="variant">
                          <input type="text" v-model="child.badge.text" placeholder="text">
                        </span>
                      </li>
                    </ul>
                  </b-collapse>
                  <hr>
                  <b-badge class="btn btn-add-menu" variant="primary" v-b-modal.modal-add-child-menu
                    @click="setParentMenu(idx, idxChild)">
                    <i class="fa fa-plus"></i>
                  </b-badge>
                </ul>
              </b-col>
            </b-row>
          </b-collapse>

          <hr>
          <b-badge class="btn btn-add-menu" variant="primary" v-b-modal.modal-add-parent-menu @click="setAddAfter(idx)">
            <i class="fa fa-plus"></i>
          </b-badge>
        </b-col>
      </b-row>

      <h5 v-if="!menuList.length">
        <b-badge class="btn btn-primary" variant="primary" v-b-modal.modal-add-parent-menu @click="setAddAfter(0)"
          size="lg">
          <i class="fa fa-plus"></i> create first menu
        </b-badge>
      </h5>
    </b-card>

    <b-modal id="modal-add-parent-menu" title="Create New Menu" :ok-visible="true">
      <FormInputMenu :add-after="addAfter" :form="formAddMenu" :on-add-menu="onAddMenu" />
      <template #modal-footer>
        <div class="w-100"></div>
      </template>
    </b-modal>

    <b-modal id="modal-add-child-menu" title="Create Child Menu" :ok-visible="true">
      <FormInputMenu :add-after="addAfter" :form="formAddMenu" :on-add-menu="onAddChildMenu" />

      <template #modal-footer>
        <div class="w-100"></div>
      </template>
    </b-modal>

    <b-button class="fix-bot-right" size="lg" variant="primary" :hidden="isLoading" @click="onSaveMenu">
      <i class="fa fa-save"> Save</i>
    </b-button>

  </b-overlay>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { SidebarMenuListPath } from '../../router/settings';
import FormInputMenu from './formInputMenu.vue';

export default {
  components: {
    FormInputMenu
  },
  data() {
    return {
      id: null,
      role_id: 1,
      roles: {},
      SidebarMenuListPath,
      menuList: [],
      formAddMenu: {},
      formAddChildMenu: {},
      addAfter: null,
      parentIndex: null,
    }
  },
  computed: {
    ...mapState({
      menuObj: state => state.menus.menu,
      isLoading: state => state.menus.isLoading,
      isError: state => state.menus.isError,
      message: state => state.menus.message,
    })
  },
  async created() {
    const roles = [...await this.fetchAllRole({})];
    roles.forEach(val => { this.roles[val.id] = val.name });
    this.role_id = this.$route.params.id;
    this.formatBasedMenus();
  },
  watch: {
    isError: function (message) {
      if (!this.isError) return;
      this.messageAlert('error', message, 5000);
    },
    message: function (message) {
      if (!this.message) return;
      this.messageAlert('success', message);
    },
  },
  methods: {
    ...mapActions('menus', ['fetchMenusByRole', 'updateMenusById']),
    ...mapActions('accessRole', ['fetchAllRole']),
    onSaveMenu() {
      const payload = {
        role_id: this.role_id,
        menu: {
          menu: this.menuList,
        }
      };
      this.updateMenusById({ id: this.id, payload })
    },
    async onCopyMenuFrom() {
      await this.fetchMenusByRole({ role_id: 1 });
      this.menuList = this.menuObj.menu?.menu.map(val => ({
        ...val
      }));
    },
    async formatBasedMenus() {
      await this.fetchMenusByRole({ role_id: this.role_id });
      this.menuList = this.menuObj.menu?.menu.map(val => ({
        ...val,
        children: val.children?.length ? [...(val.children)] : null,
      }));
      this.id = this.menuObj.id;
    },
    setAddAfter(val) {
      this.addAfter = val + 1;
    },
    setParentMenu(parentIdx, childIdx) {
      this.addAfter = childIdx + 1;
      this.parentIndex = parentIdx;
    },
    addMenu(data = {}, after = this.menuList.length) {
      const head = this.menuList.slice(0, after);
      const tail = this.menuList.slice(after, this.menuList.length);

      this.menuList = [
        ...head,
        data,
        ...tail,
      ];
    },
    addChildMenu({
      data = {}, parent, childIdx
    }) {
      if (!parent.children?.length) parent.children = [];


      const head = parent.children.slice(0, childIdx);
      const tail = parent.children.slice(childIdx, parent.children.length);

      parent.children = [
        ...head,
        data,
        ...tail,
      ];
    },
    onAddMenu() {
      this.addMenu(this.formAddMenu, this.addAfter);
      this.$bvModal.hide('modal-add-parent-menu');
      this.formAddMenu = {};
      this.addAfter = null;
    },
    onAddChildMenu() {
      this.addChildMenu({
        data: this.formAddMenu,
        parent: this.menuList[this.parentIndex],
        childIdx: this.addAfter,
      });
      this.$bvModal.hide('modal-add-child-menu');
      this.formAddMenu = {};
      this.addAfter = null;
      this.parentIndex = null;
    },
    onDeleteMenu(indexMenu) {
      this.menuList = this.menuList.filter((val, idx) => idx != indexMenu);
    },
    onDeleteChildMenu(parentMenu, idxChild) {
      parentMenu.children = parentMenu.children.filter((val, idx) => idx != idxChild);
    },
    onTitleAction(e, item) {
      if (e.target.checked === true) {
        item.wrapper = { element: "span", attributes: {} };
      } else {
        item.wrapper = {};
      }
    },
    onBadgeAction(e, item) {
      if (e.target.checked === true) {
        item.badge = { variant: "primary", text: 'New' };
      } else {
        item.badge = {};
      }
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
  },
};
</script>

<style lang="css">
.fix-bot-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 0 10px white;
}

.btn-add-menu {
  position: relative;
  top: -25px;
  float: right;

}
</style>