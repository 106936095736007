<template>
  <b-form id="form-add-parent-menu" @submit.prevent="onAddMenu">
    <ul>
      <li>Index : <input type="number" min="0" v-model="addAfter"></li>
      <li>Name : <input type="text" v-model="form.name"></li>
      <!-- <li>Is Title : <input type="checkbox" v-model="form.title" @click="isTitleAction($event, form)" /> -->
      <!-- </li> -->
      <li v-if="form.title">wrapper : {{ jsonToString(form.wrapper) }}</li>
      <section v-else>
        <li>URL : <input type="text" v-model="form.url" /></li>
        <li>Icon : <input type="text" v-model="form.icon" /></li>
      </section>
    </ul>
    <b-button variant="primary" size="sm" class="float-right" type="submit">
      save
    </b-button>
  </b-form>
</template>

<script>

export default {
  props: {
    addAfter: {
      type: Number,
      required: false
    },
    form: {
      type: Object,
      required: true
    },
    onAddMenu: {
      type: Function,
      required: true,
      default() { },
    },
  },
  mounted() {
  },
  data() {
    return {
      value: null,
      show: false,
    }
  },
  computed: {
  },
  methods: {
    isTitleAction(e, item) {
      if (e.target.checked === true) {
        item.wrapper = { element: "span", attributes: {} };
      } else {
        item.wrapper = null;
      }
    },
    jsonToString(val) {
      return JSON.stringify(val);
    },
  }
}
</script>