var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form', {
    attrs: {
      "id": "form-add-parent-menu"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onAddMenu.apply(null, arguments);
      }
    }
  }, [_c('ul', [_c('li', [_vm._v("Index : "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.addAfter,
      expression: "addAfter"
    }],
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.addAfter
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.addAfter = $event.target.value;
      }
    }
  })]), _c('li', [_vm._v("Name : "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.name,
      expression: "form.name"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "name", $event.target.value);
      }
    }
  })]), _vm.form.title ? _c('li', [_vm._v("wrapper : " + _vm._s(_vm.jsonToString(_vm.form.wrapper)))]) : _c('section', [_c('li', [_vm._v("URL : "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.url,
      expression: "form.url"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.url
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "url", $event.target.value);
      }
    }
  })]), _c('li', [_vm._v("Icon : "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.icon,
      expression: "form.icon"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.icon
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "icon", $event.target.value);
      }
    }
  })])])]), _c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "type": "submit"
    }
  }, [_vm._v(" save ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }