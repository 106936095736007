var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', [_c('i', {
    staticClass: "fa fa-backward"
  }), _c('router-link', {
    attrs: {
      "to": {
        name: _vm.SidebarMenuListPath.name
      }
    }
  }, [_vm._v(" Menus ")])], 1), _c('b-col', {
    staticClass: "text-right"
  }, [_c('b-badge', {
    staticClass: "btn btn-link",
    attrs: {
      "variant": "dark"
    },
    on: {
      "click": _vm.onCopyMenuFrom
    }
  }, [_vm._v("copy from super admin")])], 1)], 1), _c('b-card', {
    attrs: {
      "header": _vm.roles[_vm.role_id],
      "header-bg-variant": "primary",
      "header-tag": "h5",
      "header-class": "font-weight-bold"
    }
  }, [_vm._l(_vm.menuList, function (item, idx) {
    var _item$children, _item$children2;
    return _c('b-row', {
      key: item.id
    }, [_c('b-col', {
      attrs: {
        "md": "10"
      }
    }, [_c('span', {
      staticStyle: {
        "min-width": "300px"
      },
      attrs: {
        "id": "menu-name"
      }
    }, [_c('a', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'children' + idx,
        expression: "'children' + idx"
      }],
      staticClass: "btn"
    }, [_c('i', {
      class: item.icon
    }), _vm._v(" " + _vm._s(item.name) + " "), item.title ? _c('b', [_vm._v("[Title]")]) : _vm._e()]), item.is_badge ? _c('span', [_c('b-badge', {
      attrs: {
        "variant": item.badge.variant
      }
    }, [_vm._v(" " + _vm._s(item.badge.text) + " ")])], 1) : _vm._e()]), !item.title ? _c('span', [_c('span', {
      staticClass: "px-3"
    }, [_vm._v("|")]), _c('span', {
      staticClass: "mr-2",
      attrs: {
        "id": "menu-btn-edit"
      }
    }, [_c('b-badge', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'edit' + idx,
        expression: "'edit' + idx"
      }],
      staticClass: "btn btn-link",
      attrs: {
        "variant": "primary"
      }
    }, [_vm._v("edit")])], 1), (_item$children = item.children) !== null && _item$children !== void 0 && _item$children.length ? _c('span', {
      staticClass: "mr-2",
      attrs: {
        "id": "menu-btn-child"
      }
    }, [_c('b-badge', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'children' + idx,
        expression: "'children' + idx"
      }],
      staticClass: "btn btn-link",
      attrs: {
        "variant": "info"
      }
    }, [_vm._v("show child")])], 1) : _vm._e(), !item.title && !((_item$children2 = item.children) !== null && _item$children2 !== void 0 && _item$children2.length) ? _c('span', {
      staticClass: "mr-2",
      attrs: {
        "id": "menu-btn-add-child"
      }
    }, [_c('b-badge', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.modal-add-child-menu",
        modifiers: {
          "modal-add-child-menu": true
        }
      }],
      staticClass: "btn btn-link",
      attrs: {
        "variant": "warning"
      },
      on: {
        "click": function ($event) {
          return _vm.setParentMenu(idx, -1);
        }
      }
    }, [_vm._v("Add child")])], 1) : _vm._e(), _c('span', {
      staticClass: "mr-2",
      attrs: {
        "id": "menu-btn-child"
      }
    }, [_c('b-badge', {
      staticClass: "btn btn-link",
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function ($event) {
          return _vm.onDeleteMenu(idx);
        }
      }
    }, [_vm._v("remove")])], 1)]) : _vm._e(), _c('b-collapse', {
      attrs: {
        "id": 'edit' + idx
      }
    }, [_c('ul', [_c('li', [_vm._v("Name "), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.name,
        expression: "item.name"
      }],
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": item.name
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) { return; }
          _vm.$set(item, "name", $event.target.value);
        }
      }
    })]), !item.title ? _c('section', [_c('li', [_vm._v("URL "), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.url,
        expression: "item.url"
      }],
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": item.url
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) { return; }
          _vm.$set(item, "url", $event.target.value);
        }
      }
    })]), _c('li', [_vm._v("Icon "), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.icon,
        expression: "item.icon"
      }],
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": item.icon
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) { return; }
          _vm.$set(item, "icon", $event.target.value);
        }
      }
    })]), _c('li', [_vm._v("badge : "), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.is_badge,
        expression: "item.is_badge"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(item.is_badge) ? _vm._i(item.is_badge, null) > -1 : item.is_badge
      },
      on: {
        "click": function ($event) {
          return _vm.onBadgeAction($event, item);
        },
        "change": function ($event) {
          var $$a = item.is_badge,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(item, "is_badge", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(item, "is_badge", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(item, "is_badge", $$c);
          }
        }
      }
    }), item.is_badge ? _c('span', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.badge.variant,
        expression: "item.badge.variant"
      }],
      attrs: {
        "type": "text",
        "placeholder": "variant"
      },
      domProps: {
        "value": item.badge.variant
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) { return; }
          _vm.$set(item.badge, "variant", $event.target.value);
        }
      }
    }), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.badge.text,
        expression: "item.badge.text"
      }],
      attrs: {
        "type": "text",
        "placeholder": "text"
      },
      domProps: {
        "value": item.badge.text
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) { return; }
          _vm.$set(item.badge, "text", $event.target.value);
        }
      }
    })]) : _vm._e()])]) : _vm._e()])]), item.children ? _c('b-collapse', {
      attrs: {
        "id": 'children' + idx
      }
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "md": "10"
      }
    }, _vm._l(item.children, function (child, idxChild) {
      return _c('ul', {
        key: idxChild
      }, [_c('span', {
        staticStyle: {
          "min-width": "300px"
        },
        attrs: {
          "id": "child-name"
        }
      }, [_c('a', {
        directives: [{
          name: "b-toggle",
          rawName: "v-b-toggle",
          value: 'editchild' + idx + idxChild,
          expression: "'editchild' + idx + idxChild"
        }],
        staticClass: "btn"
      }, [_c('i', {
        class: child.icon
      }), _vm._v(" " + _vm._s(child.name) + " ")]), child.is_badge ? _c('span', [_c('b-badge', {
        attrs: {
          "variant": child.badge.variant
        }
      }, [_vm._v(_vm._s(child.badge.text))])], 1) : _vm._e()]), _c('span', {
        staticClass: "px-3"
      }, [_vm._v("|")]), _c('span', [_c('b-badge', {
        directives: [{
          name: "b-toggle",
          rawName: "v-b-toggle",
          value: 'editchild' + idx + idxChild,
          expression: "'editchild' + idx + idxChild"
        }],
        staticClass: "btn btn-link mr-2",
        attrs: {
          "variant": "secondary"
        }
      }, [_vm._v("edit")])], 1), _c('span', [_c('b-badge', {
        staticClass: "btn btn-link mr-2",
        attrs: {
          "variant": "danger"
        },
        on: {
          "click": function ($event) {
            return _vm.onDeleteChildMenu(item, idxChild);
          }
        }
      }, [_vm._v("remove")])], 1), _c('b-collapse', {
        attrs: {
          "id": 'editchild' + idx + idxChild
        }
      }, [_c('ul', [_c('li', [_vm._v("name : "), _c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: child.name,
          expression: "child.name"
        }],
        attrs: {
          "type": "text"
        },
        domProps: {
          "value": child.name
        },
        on: {
          "input": function ($event) {
            if ($event.target.composing) { return; }
            _vm.$set(child, "name", $event.target.value);
          }
        }
      })]), _c('li', [_vm._v("url : "), _c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: child.url,
          expression: "child.url"
        }],
        attrs: {
          "type": "text"
        },
        domProps: {
          "value": child.url
        },
        on: {
          "input": function ($event) {
            if ($event.target.composing) { return; }
            _vm.$set(child, "url", $event.target.value);
          }
        }
      })]), _c('li', [_vm._v("icon : "), _c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: child.icon,
          expression: "child.icon"
        }],
        attrs: {
          "type": "text"
        },
        domProps: {
          "value": child.icon
        },
        on: {
          "input": function ($event) {
            if ($event.target.composing) { return; }
            _vm.$set(child, "icon", $event.target.value);
          }
        }
      })]), _c('li', [_vm._v("badge : "), _c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: child.is_badge,
          expression: "child.is_badge"
        }],
        attrs: {
          "type": "checkbox"
        },
        domProps: {
          "checked": Array.isArray(child.is_badge) ? _vm._i(child.is_badge, null) > -1 : child.is_badge
        },
        on: {
          "click": function ($event) {
            return _vm.onBadgeAction($event, child);
          },
          "change": function ($event) {
            var $$a = child.is_badge,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v);
              if ($$el.checked) {
                $$i < 0 && _vm.$set(child, "is_badge", $$a.concat([$$v]));
              } else {
                $$i > -1 && _vm.$set(child, "is_badge", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
              }
            } else {
              _vm.$set(child, "is_badge", $$c);
            }
          }
        }
      }), child.is_badge ? _c('span', [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: child.badge.variant,
          expression: "child.badge.variant"
        }],
        attrs: {
          "type": "text",
          "placeholder": "variant"
        },
        domProps: {
          "value": child.badge.variant
        },
        on: {
          "input": function ($event) {
            if ($event.target.composing) { return; }
            _vm.$set(child.badge, "variant", $event.target.value);
          }
        }
      }), _c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: child.badge.text,
          expression: "child.badge.text"
        }],
        attrs: {
          "type": "text",
          "placeholder": "text"
        },
        domProps: {
          "value": child.badge.text
        },
        on: {
          "input": function ($event) {
            if ($event.target.composing) { return; }
            _vm.$set(child.badge, "text", $event.target.value);
          }
        }
      })]) : _vm._e()])])]), _c('hr'), _c('b-badge', {
        directives: [{
          name: "b-modal",
          rawName: "v-b-modal.modal-add-child-menu",
          modifiers: {
            "modal-add-child-menu": true
          }
        }],
        staticClass: "btn btn-add-menu",
        attrs: {
          "variant": "primary"
        },
        on: {
          "click": function ($event) {
            return _vm.setParentMenu(idx, idxChild);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-plus"
      })])], 1);
    }), 0)], 1)], 1) : _vm._e(), _c('hr'), _c('b-badge', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.modal-add-parent-menu",
        modifiers: {
          "modal-add-parent-menu": true
        }
      }],
      staticClass: "btn btn-add-menu",
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.setAddAfter(idx);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-plus"
    })])], 1)], 1);
  }), !_vm.menuList.length ? _c('h5', [_c('b-badge', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-add-parent-menu",
      modifiers: {
        "modal-add-parent-menu": true
      }
    }],
    staticClass: "btn btn-primary",
    attrs: {
      "variant": "primary",
      "size": "lg"
    },
    on: {
      "click": function ($event) {
        return _vm.setAddAfter(0);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" create first menu ")])], 1) : _vm._e()], 2), _c('b-modal', {
    attrs: {
      "id": "modal-add-parent-menu",
      "title": "Create New Menu",
      "ok-visible": true
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticClass: "w-100"
        })];
      },
      proxy: true
    }])
  }, [_c('FormInputMenu', {
    attrs: {
      "add-after": _vm.addAfter,
      "form": _vm.formAddMenu,
      "on-add-menu": _vm.onAddMenu
    }
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-add-child-menu",
      "title": "Create Child Menu",
      "ok-visible": true
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticClass: "w-100"
        })];
      },
      proxy: true
    }])
  }, [_c('FormInputMenu', {
    attrs: {
      "add-after": _vm.addAfter,
      "form": _vm.formAddMenu,
      "on-add-menu": _vm.onAddChildMenu
    }
  })], 1), _c('b-button', {
    staticClass: "fix-bot-right",
    attrs: {
      "size": "lg",
      "variant": "primary",
      "hidden": _vm.isLoading
    },
    on: {
      "click": _vm.onSaveMenu
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }, [_vm._v(" Save")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }